import React from 'react'
import styled from 'styled-components';
import theme from '../utils/theme';

import Link from '../components/Link';
import Section from "../components/Section";
import Container from "../components/Container";
import Typography from "../components/Typography";
import { Flex, Box } from "../components/Grid";

const StyledSection = styled(Section)`
  position: relative;
  height: 300px;
  display: flex;
  align-items: center;
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${p => p.image});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
  }

  ${p => p.headerSpace && `
    margin-top: 80px;
    @media (max-width: 832px) {
      margin-top: 65px;
    }
  `}
`

const SmallHeroBand = ({ contents, headerSpace }) => {
  return (
    <StyledSection image={contents.image} headerSpace={headerSpace}>
      <Container>
        <Flex justifyContent='center' alignItems='center'>
          <Box px={0} textAlign='center' color={theme.colors.light}>
            {contents.name && <Typography tag="span" mb={3} fontSize={3} fontFamily={1} textTransform="uppercase" letterSpacing="2px" >{contents.name}</Typography>}
            {contents.title && <Typography tag="h3" mb={0}>{contents.title}</Typography>}
            {contents.description && <Typography tag="span" my={3}>{contents.description}</Typography>}
            {contents.button && 
              <Flex>
                <Link variant={'button-primary'} href={contents.link}>{contents.button}</Link>
              </Flex>
            }
          </Box>
        </Flex>
      </Container>
    </StyledSection>
  )
}

export default SmallHeroBand