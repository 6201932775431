import React from "react"
import styled from "styled-components";
import theme from "../utils/theme";

import Link from "../components/Link"
import Section from "../components/Section"
import Container from "../components/Container"
import Typography from "../components/Typography"
import { Flex, Box } from "../components/Grid"

export const SmallBand = ({ background = theme.colors.primary }) => {
  return (
    <Section pt='34px' pb='34px' background={background}>
      <Container>
        <Flex justifyContent={['center','center','space-between']} alignItems='center' textAlign={['center','center','left']}>
          <Typography tag='h3' mb={[4,4,0]} color={theme.colors.light} spanStyles={{color: theme.colors.dark}} fontSize={['28px','32px','35px']}>Cuéntanos tu idea y <span>comencemos</span> tu proyecto.</Typography>
          <Link href={'/start-your-project'} variant='button-light-ghost' color={theme.colors.light} >Contáctanos</Link>
        </Flex>
      </Container>
    </Section>
  )
}


const StyledSection = styled(Section)`
  position: relative;
  z-index: 0;
  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${(props) => props.bgImg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
  }
`

export const MediumBand = ({ id, title, description, bg, bgImg, colorTitle, colorDescription, button, buttonVariant, link }) => {
  return (
    <StyledSection pt='74px' pb='74px' id={id} bg={bg} bgImg={bgImg}>
      <Container>
        <Flex justifyContent="center">
          <Box textAlign="center">
            <Typography tag="h2" mb={3} color={colorTitle ? colorTitle : theme.colors.light} fontSize={["38px", "48px"]} lineHeight="48px" dangerouslySetInnerHTML={{ __html: title }}/>
            <Typography tag="p" mb={5} color={colorDescription ? colorDescription : theme.colors.light} fontSize={["16", "18px"]} dangerouslySetInnerHTML={{ __html: description }} />
          </Box>
        </Flex>
        <Flex justifyContent="center">
          <Box display="flex" alignItems="center" justifyContent='center' textAlign="center">
            <Link href={link} variant={buttonVariant} >{button}</Link>
          </Box>
        </Flex>
      </Container>
    </StyledSection>
  )
}
